import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import YearPicker from "../components/YearPicker";
import monthsList from "../../../constants/months";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
function MonthlyTotalsTable(_a) {
    var policies_by_month = _a.policies_by_month, year = _a.year, setYear = _a.setYear, department = _a.department;
    var columns = department === "Medicare"
        ? [
            { id: "month", label: "month" },
            {
                id: "Supplement",
                label: "SUP",
            },
            {
                id: "MAPD",
                label: "MAPD",
            },
            {
                id: "MA",
                label: "MA",
            },
            {
                id: "PDP",
                label: "PDP",
            },
            {
                id: "total_policies",
                label: "Total Policies",
            },
            {
                id: "total_premium",
                label: "Total Premium",
            },
        ]
        : department === "IMM"
            ? [
                { id: "month", label: "month" },
                {
                    id: "IMM",
                    label: "IMM",
                },
                {
                    id: "STM",
                    label: "STM",
                },
                {
                    id: "Dental",
                    label: "Dental",
                },
                {
                    id: "LTC",
                    label: "LTC",
                },
                {
                    id: "Vision",
                    label: "Vision",
                },
                {
                    id: "Other",
                    label: "Other",
                },
                {
                    id: "critical_illness",
                    label: "Critical Illness",
                },
                {
                    id: "total_policies",
                    label: "Total Policies",
                },
                {
                    id: "total_premium",
                    label: "Total Premium",
                },
            ]
            : department === "Rosemark"
                ? [
                    { id: "month", label: "month" },
                    {
                        id: "Life",
                        label: "Life Policies",
                    },
                    {
                        id: "life_premium",
                        label: "Life Premium",
                    },
                    {
                        id: "Financial",
                        label: "Financial Premium",
                    },
                    {
                        id: "financial_premium",
                        label: "Financial Premium",
                    },
                    {
                        id: "total_policies",
                        label: "Total Policies",
                    },
                    {
                        id: "total_premium",
                        label: "Total Premium",
                    },
                ]
                : [];
    var colHeaders = department === "Medicare" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { style: headerLeft, align: "center", colSpan: 1 },
            React.createElement("strong", null, "SUP")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MAPD")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MA")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "PDP")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Policies")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")))) : department === "IMM" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "IMM")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "STM")),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Dental")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "LTC")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Vision")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Other")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Critical Illness")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Policies")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")))) : department === "Rosemark" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Life Policies")),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Life Premium")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Financial Policies")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Financial Premium")),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 },
            React.createElement("strong", null, "Total Policies")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "Total Premium")))) : (React.createElement(React.Fragment, null));
    return (React.createElement(Paper, null, policies_by_month && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "left", style: header, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: columns.length - 3 },
                        React.createElement(YearPicker, { year: year, setYear: setYear })),
                    React.createElement(TableCell, { align: "left", style: headerRight, colSpan: 1 })),
                React.createElement(TableRow, null, colHeaders)),
            React.createElement(TableBody, null, policies_by_month &&
                policies_by_month.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.month + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        if (column.id.includes("_premium")) {
                            var rounded = value === null || value === void 0 ? void 0 : value.toFixed(2);
                            value =
                                "$" +
                                    parseInt(rounded).toLocaleString("en-US");
                        }
                        if (column.id == "month") {
                            var monthName = monthsList[value - 1];
                            value = monthName;
                        }
                        return (React.createElement(TableCell, { key: row.month +
                                column.id, style: borders, align: !column.id.includes("month")
                                ? "center"
                                : "left" }, value));
                    })));
                })))))));
}
export default MonthlyTotalsTable;
