import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Button, Typography } from "@mui/material";
import PolicyReferralsForm from "../components/PolicyReferralsForm";
import PolicyReferralsTable from "./PolicyReferralsTable";
import NumberFormat from "react-number-format";
import PageTitle from "../../../components/PageTitle";
import axios from "axios";
import { format_date } from "../../../utils/dateUtils";
function PolicyReferrals(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var response_data = _a.response_data, serverErrors = _a.errors, csrf = _a.csrf;
    var formData = response_data.form_data;
    var download = function (type) {
        axios
            .post("/production-boards/export/download", {
            csrf: csrf,
            board: "",
            department: "Policy-Referrals",
            formData: JSON.stringify(formData),
            download_type: type,
        }, { responseType: "blob" })
            .then(function (response) {
            var _a;
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            var today = format_date(new Date());
            var fileName = "Policy-Referrals_" + today + ".csv";
            link.setAttribute("download", fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            (_a = link === null || link === void 0 ? void 0 : link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
        })
            .catch(function () {
            console.error("Error exporting.");
        });
    };
    var insurance_types = [
        "Supplement",
        "MA",
        "MAPD",
        "PDP",
        "Dental",
        "Vision",
        "Other",
        "IMM",
        "STM",
        "Critical",
        "Accident",
        "Teladoc",
        "LTC",
        "Life",
        "Financial",
    ];
    var applications = ["Total", "Health", "Life", "Financial"];
    var download_types = ["quick", "leads", "policies"];
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyReferralsForm, { data: response_data, serverErrors: serverErrors }),
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Summary" })),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Policies")),
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Leads")),
            React.createElement(Grid, { xs: 6, mb: 2 },
                React.createElement(Typography, { variant: "h5" }, "Insurance Types")),
            React.createElement(Grid, { xs: 3 },
                React.createElement(Grid, { xs: 12, container: true }, applications.map(function (application) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { xs: 6 },
                            application,
                            " Policies"),
                        React.createElement(Grid, { xs: 6 }, response_data.summary
                            .policies_counts[0]["".concat(application.toLowerCase(), "_policies")]),
                        React.createElement(Grid, { xs: 6 },
                            application,
                            " Premium"),
                        React.createElement(Grid, { xs: 6 },
                            React.createElement(NumberFormat, { value: response_data.summary
                                    .policies_counts[0]["".concat(application.toLowerCase(), "_premium")], displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))));
                }))),
            React.createElement(Grid, { xs: 3, mb: 2 },
                React.createElement(Grid, { xs: 12, container: true },
                    React.createElement(Grid, { xs: 6 }, "Total leads"),
                    React.createElement(Grid, { xs: 6 }, (_b = response_data.summary.leads_counts[0]
                        .total_leads) !== null && _b !== void 0 ? _b : 0),
                    React.createElement(Grid, { xs: 6 }, "Financial Leads"),
                    React.createElement(Grid, { xs: 6 }, (_c = response_data.summary.leads_counts[0]
                        .financial_leads) !== null && _c !== void 0 ? _c : 0),
                    React.createElement(Grid, { xs: 6 }, "IMM Leads"),
                    React.createElement(Grid, { xs: 6 }, (_d = response_data.summary.leads_counts[0].imm_leads) !== null && _d !== void 0 ? _d : 0),
                    React.createElement(Grid, { xs: 6 }, "Life Leads"),
                    React.createElement(Grid, { xs: 6 }, (_e = response_data.summary.leads_counts[0].life_leads) !== null && _e !== void 0 ? _e : 0),
                    React.createElement(Grid, { xs: 6 }, "LTC Leads"),
                    React.createElement(Grid, { xs: 6 }, (_f = response_data.summary.leads_counts[0].ltc_leads) !== null && _f !== void 0 ? _f : 0),
                    React.createElement(Grid, { xs: 6 }, "Medicare Leads"),
                    React.createElement(Grid, { xs: 6 }, (_g = response_data.summary.leads_counts[0]
                        .medicare_leads) !== null && _g !== void 0 ? _g : 0),
                    React.createElement(Grid, { xs: 6 }, "Membership Leads"),
                    React.createElement(Grid, { xs: 6 }, (_h = response_data.summary.leads_counts[0]
                        .membership_leads) !== null && _h !== void 0 ? _h : 0))),
            React.createElement(Grid, { xs: 4, mb: 2 },
                React.createElement(Grid, { xs: 12, container: true }, insurance_types.map(function (type) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { xs: 4 }, type),
                        React.createElement(Grid, { xs: 4 }, response_data.summary
                            .policies_counts[0]["".concat(type.toLowerCase(), "_policies")]),
                        React.createElement(Grid, { xs: 4 },
                            React.createElement(NumberFormat, { value: response_data.summary
                                    .policies_counts[0]["".concat(type.toLowerCase(), "_premium")], displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))));
                }))),
            response_data.summary.leads_counts[0].total_leads > 0 && (React.createElement(Grid, { container: true, xs: 12, spacing: 1 }, download_types.map(function (type) {
                return (React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "outlined", color: "primary", fullWidth: true, onClick: function () { return download(type); } }, "Download ".concat(type, " Report"))));
            }))),
            React.createElement(Grid, { xs: 12 },
                React.createElement(PolicyReferralsTable, { data: response_data.summary.total_by_referral, title: "Membership Data" })))));
}
export default PolicyReferrals;
